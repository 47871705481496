import {Button, Drawer} from "antd";
import {MenuFoldOutlined, SendOutlined, UserOutlined, WhatsAppOutlined} from '@ant-design/icons';
import React, {useState} from "react";

export const Head = () => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    return (
        <header className={"sticky top-0 bg-white z-20 w-full bg-opacity-90 backdrop-blur-2xl"}>
            <div className="flex flex-row justify-between md:container md:mx-auto items-center p-2 md:py-5">
                <img src={"/assets/logos/logo-v3.svg"} alt={"Логотип DEVInside версии 3.0"} className={"h-[30px] md:h-full"} width={140}/>
                <div className="hidden flex-row gap-1 items-center md:flex">
                    <Button href={"mailto:foer@devinside.ru"} rootClassName={"me-5"} type={"text"} size={"large"} icon={<UserOutlined />}>foer@devinside.ru</Button>
                    {/*<Button type={"dashed"} size={"large"} icon={<WhatsAppOutlined />}/>*/}
                    <Button href={"https://t.me/vyachesIavv"} type={"dashed"} size={"large"} icon={<SendOutlined />}/>
                    <Button href={"https://vk.com/devinside"} type={"dashed"} size={"large"}>Вконтакте</Button>
                </div>
                <Button rootClassName={"visible md:hidden"} type={"text"} onClick={showDrawer} size={"large"} icon={<MenuFoldOutlined/>}/>
            </div>
            <hr className={"border-0 bg-gray-200 hidden md:block mt-0"} style={{height: 1}} />
            <div className="hidden flex-row md:container md:mx-auto items-center gap-5 justify-center md:flex">
                <Button disabled={true} type={"text"} size={"large"}>О нас</Button>
                <Button disabled={true} type={"text"} size={"large"}>Создание сайтов</Button>
                <Button disabled={true} type={"text"} size={"large"}>Сопровождение сайтов</Button>
                <Button disabled={true} type={"text"} size={"large"}>Портфолио</Button>
                <Button href={"#pricing"} type={"text"} size={"large"}>Цены</Button>
                <Button href={"#contacts"} type={"text"} size={"large"}>Контакты</Button>
            </div>
            <hr className={"border-0 bg-gray-200 mb-0 mt-0 md:mt-2"} style={{height: 1}} />
            <Drawer title="Меню" placement="right" onClose={onClose} open={open}>
                <div className="flex flex-col text-start gap-2">
                    <Button href={"mailto:foer@devinside.ru"} type={"primary"} size={"large"} className={"text-gray-800"} icon={<UserOutlined />}>foer@devinside.ru</Button>
                    <Button href={"https://t.me/vyachesIavv"} type={"dashed"} size={"large"} icon={<SendOutlined />}>Telegram</Button>
                    <Button href={"https://vk.com/devinside"} type={"dashed"} size={"large"}>Вконтакте</Button>
                    <Button disabled={true} type={"dashed"} size={"large"}>О нас</Button>
                    <Button disabled={true} type={"dashed"} size={"large"}>Создание сайтов</Button>
                    <Button disabled={true} type={"dashed"} size={"large"}>Сопровождение сайтов</Button>
                    <Button disabled={true} type={"dashed"} size={"large"}>Портфолио</Button>
                    <Button href={"#pricing"} type={"dashed"} size={"large"}>Цены</Button>
                    <Button href={"#contacts"} type={"dashed"} size={"large"}>Контакты</Button>
                </div>

            </Drawer>
        </header>
    )
}