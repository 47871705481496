import {CheckOutlined} from "@ant-design/icons";
import React from "react";
import {Button, Skeleton} from "antd";

export const PricingCard = ({featuresList,name,price,description,hour_work, rootStyle, skelRows= 3}) => {
    return (
        <div className="Manrope flex flex-1 flex-col gap-3 h-full mx-5 md:m-0 p-6 md:p-0 border-solid border border-gray-300 rounded-xl md:border-none">
            <h3 className={"m-0 text-xl"} style={{lineHeight: 0.3}}>{name}</h3>
            <p className={"m-0 text-[30px] font-light"}>от {price} ₽</p>
            <p>{description}</p>
            <hr className={"border-0 bg-gray-200 mb-0 w-full"} style={{height: 2}}/>
            <div className="flex flex-col gap-3 flex" style={rootStyle}>
                <p className={"font-bold mb-0"}>Включенные услуги:</p>
                {
                    featuresList.map((feature,index) => {
                        if (feature === "skeleton")
                            return <Skeleton key={index} rootClassName={"mb-1.5"} size={"small"} paragraph={{ rows: skelRows }} />
                        return (
                            <div className="flex flex-row gap-2 font-light" key={index}>
                                <CheckOutlined className={"text-green-400"} /><span>{feature}</span>
                            </div>
                        )
                    })
                }
            </div>
            <hr className={"border-0 bg-gray-200 mb-0 w-full"} style={{height: 2}}/>
            <p className={"font-bold mb-0"}>Цена работ не входящих в тариф:</p>
            <p className={"m-0 font-light mb-3"}>{hour_work} ₽ / час</p>
            <Button type={"primary"} size={"large"}>Узнать подробнее</Button>
        </div>
    )
}