import {Head} from "../components/header.component";

export const MainLayout = ({children}) => {
    return (
        <div className="h-min-screen flex flex-col">
            <Head/>
            <main>
            {children}
            </main>
            <section className="bg-white Manrope">
                <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
                    <p className="mt-8 text-base leading-6 text-center text-gray-400">
                        © 2023 [DEV] Inside, все права защищены.
                    </p>
                </div>
            </section>
        </div>
    );
}