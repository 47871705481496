
import Marquee from "react-fast-marquee";
import React from "react";
import {PricingCard} from "../components/pricecard.component";
import {Button} from "antd";
import { Input } from 'antd';

const { TextArea } = Input;

{/* eslint-disable */}

export const HomePage = () => {
    return (
        <>
            <section className={"relative"}>
                <div className="flex flex-col md:container md:mx-auto">
                    <img src={"/assets/images/bg_hero.png"} alt={"Главная страница"}
                         className={"w-full h-screen bg-cover md:h-auto absolute top-0 left-0 opacity-20 -z-10"}
                         style={{objectFit: "cover"}}/>
                    <div className="flex flex-row justify-center mt-20">
                        <div className="flex flex-col gap-3 items-center md:items-start px-4 md:px-0">
                            <h1 className={"Manrope font-black text-[46px] md:text-[56px] mb-2 mt-5 md:mt-20"}
                                style={{lineHeight: 1}}>Комплексная разработка сайта</h1>
                            <h2 className={"Manrope font-light text-[19px] md:text-[24px]"}>Возьмем на себя все
                                трудности по
                                созданию вашего<br/>веб-сайта</h2>
                            <a href="#" className={"main-schema-button"}>Заказать веб-сайт</a>
                        </div>
                        <div className="hidden md:flex flex-col">
                            <img src={"/assets/images/god_of_creation.gif"} alt={"Бог разработчик>"}
                                 className={"w-full"}/>
                        </div>
                    </div>
                    <div className={"px-5 md:p-0 overflow-x-scroll md:overflow-x-auto"}>
                        <div className="flex flex-row justify-between gap-5 Manrope mt-20 w-[1280px] md:w-fit">
                            <div
                                className="flex flex-1 flex-col gap-3 justify-center items-center text-center rounded-xl p-3 border border-solid border-gray-200 md:border-0 bg-white md:bg-transparent">
                                <img src={"/assets/images/send-mail 1.png"} width={58} alt={"Бекап"}/>
                                <label>Оперативный ответ на заявки</label>
                            </div>
                            <div
                                className="flex flex-1 flex-col gap-3 justify-center items-center text-center rounded-xl p-3 border border-solid border-gray-200 md:border-0 bg-white md:bg-transparent">
                                <img src={"/assets/images/data-analytics 1.png"} width={58} alt={"Бекап"}/>
                                <label>Улучшаем сайт для поисковых систем</label>
                            </div>
                            <div
                                className="flex flex-1 flex-col gap-3 justify-center items-center text-center rounded-xl p-3 border border-solid border-gray-200 md:border-0 bg-white md:bg-transparent">
                                <img src={"/assets/images/bug 1.png"} width={58} alt={"Бекап"}/>
                                <label>Защита сайта от вирусов</label>
                            </div>
                            <div
                                className="flex flex-1 flex-col gap-3 justify-center items-center text-center rounded-xl p-3 border border-solid border-gray-200 md:border-0 bg-white md:bg-transparent">
                                <img src={"/assets/images/backup-file 1.png"} width={58} alt={"Бекап"}/>
                                <label>Ежедневное резервное копирование сайта</label>
                            </div>
                            <div
                                className="flex flex-1 flex-col gap-3 justify-center items-center text-center rounded-xl p-3 border border-solid border-gray-200 md:border-0 bg-white md:bg-transparent">
                                <img src={"/assets/images/browsers 1.png"} width={58} alt={"Бекап"}/>
                                <label>Бесперебойная работа сайта 24/7</label>
                            </div>
                        </div>
                        <hr className={"border-0 bg-gray-100 mt-10 mb-0"} style={{height: 1}}/>
                    </div>
                </div>
            </section>
            <section className={"flex flex-col justify-center items-center bg-gray-50 w-full py-5 md:py-10"}>
                <h2 className={"w-fit Manrope md:text-[30px] text-center mt-0"} style={{lineHeight: 1}}>Мы работаем<br/>со всеми популярными CMS</h2>
                <div className="flex md:container md:mx-auto flex-wrap items-center mt-3 md:mt-10 justify-between mb-3 flex-col md:flex-row">
                    <a href="https://www.1c-bitrix.ru/" target={"_blank"} className="flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                        <img
                            src="/assets/cms/1c-bitrix-logos-idYVgQkhn-.svg"
                            alt="image"
                            className="w-full h-10"
                        />
                    </a>
                    <a href="https://www.drupal.org/" target={"_blank"} className="flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                        <img
                            src="/assets/cms/drupal-logos-idxyRLY8SK.svg"
                            alt="image"
                            className="w-full h-10"
                        />
                    </a>
                    <a href="https://octobercms.com/" target={"_blank"} className="flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                        <img
                            src="/assets/cms/october-cms-logos-idxBw-uTqc.svg"
                            alt="image"
                            className="w-full h-10"
                        />
                    </a>
                    <a href="https://www.opencart.com/" target={"_blank"} className="flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                        <img
                            src="/assets/cms/opencart-logos-idMNQIHTVO.svg"
                            alt="image"
                            className="w-full h-10"
                        />
                    </a>
                    <a href="https://wordpress.com/" target={"_blank"} className="flex w-[150px] items-center justify-center py-5 2xl:w-[180px]">
                        <img
                            src="/assets/cms/wordpress-com-logos-idVOB5r3Nv.svg"
                            alt="image"
                            className="w-full h-10"
                        />
                    </a>

                </div>
                <p className={"Manrope opacity-50"}>нет вашей CRM системы? изучим!</p>
            </section>
            <section id={"pricing"} className={"flex flex-col md:container md:mx-auto py-14 justify-center items-center mt-10"} style={{scrollMarginTop: 150}}>
                <h2 className={"w-fit Manrope md:text-[30px] text-center my-0"} style={{lineHeight: 1}}>Сколько стоит создание сайта?</h2>
                <p className={"mb-14 Manrope opacity-60 text-center"}>Тарифы сформированы под объем потребностей и размеры компании,<br/>точную стоимость узнавайте при обращении</p>
                <div className="flex flex-col md:flex-row mt-10 justify-between gap-14">
                    <PricingCard price={"25,000"} name={"Базовый"} hour_work={2000} description={<>Работа занимает от 3-ех деней.<br/>Приоритет обслуживания: Стандартный</>} featuresList={[
                        "Администрирование сайта",
                        "Мониторинг работоспособности",
                        "Обслуживание сайта (CMS)",
                        "Резервное копирование 1 раз/нед",
                        "Вопросы с тех. службами",
                        "Защита от вирусов",
                        "Поддержка электронной почты",
                        "Общие вопросы",
                    ]} />
                    <PricingCard price={"30,000"} name={"Оптимальный"} hour_work={1700} description={<>Работа занимает от одной недели.<br/>Приоритет обслуживания: Высокий</>} featuresList={[
                        "Все услуги из тарифа 'Базовый'",
                        "Добавление/Изменение функционала",
                        "Базовая настройка метрики Яндекс",
                        "skeleton",
                    ]} />
                    <PricingCard rootStyle={{marginBottom:-13}} skelRows={2} price={"50,000"} name={"Хотим все!"} hour_work={2400} description={<>Работа занимает от двух недель.<br/>Приоритет обслуживания: В первую очередь</>} featuresList={[
                        "Все услуги из тарифа 'Оптимальный'",
                        "Полная настройка и оптимизация вебмастера",
                        "Полная настройка метрики Яндекс",
                        "skeleton",
                    ]} />
                </div>
            </section>
            <section id={"contacts"} className="bg-gray-100 Manrope py-10 hidden md:block" style={{scrollMarginTop: 150}}>
                <div className="md:container md:mx-auto flex flex-row justify-center items-center gap-20">
                    <img src="/assets/images/rocket.gif" className="img-fluid" height={300}/>
                    <div className="flex flex-col text-center md:text-start w-1/2">
                        <h1 className="w-100 text-[30px] mb-0">У вас остались вопросы?</h1>
                        <p className="f-20 text-[18px] my-0 opacity-50 mb-10">Напишите нам!</p>
                        <Input rootClassName={"mb-3"} placeholder={"Имя"} />
                        <Input rootClassName={"mb-3"} placeholder={"Почта"} />
                        <TextArea rows={4} rootClassName={"mb-4"} placeholder="Задайте свой вопрос!" maxLength={6} />
                        <Button type={"dashed"} size={"large"}>Написать нам</Button>
                    </div>
                </div>
            </section>
            <section className={"flex flex-col md:container md:mx-auto py-14 justify-center items-center mt-10"}>
                <h2 className={"w-fit Manrope md:text-[30px] text-center my-0"} style={{lineHeight: 1}}>Наши клиенты</h2>
                <Marquee speed={30} autoFill={true} gradient={true} className={"mt-14 opacity-50"} style={{filter: "grayscale(100)"}}>
                    <img
                        src="/assets/logos/ba-logo.svg"
                        alt="image"
                        className="w-full h-12 me-10"
                    />
                    <img
                        src="/assets/logos/sb-logo.svg"
                        alt="image"
                        className="w-full h-12 me-10"
                    />
                    <img
                        src="/assets/logos/pg-logo.svg"
                        alt="image"
                        className="w-full h-12 me-10"
                    />
                    <img
                        src="/assets/logos/su-logo.svg"
                        alt="image"
                        className="w-full h-12 me-10"
                    />
                </Marquee>
            </section>

        </>
    )
}