import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {HomePage} from "./pages/home.page";
import {MainLayout} from "./layouts/main.layout";
export const useRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MainLayout><HomePage/></MainLayout>} exact/>
            <Route path="*" element={<Navigate to="/" />} exact/>
        </Routes>
    )
}