import {useRoutes} from "./routes";
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";
import ru_RU from "antd/lib/locale/ru_RU";

function App() {
  const routes = useRoutes();
  return (
      <ConfigProvider theme={{
          "token": {
              "colorPrimary": "#faad14",
              "colorInfo": "#faad14",
              "colorTextBase": "#151515",
              "borderRadius": 10,
              "wireframe": true
          },
      }} locale={ru_RU}>
          <BrowserRouter>
            {routes}
          </BrowserRouter>
      </ConfigProvider>
  );
}

export default App;
